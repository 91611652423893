@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #232323; /* Matches the background-dark */
}

::-webkit-scrollbar-thumb {
  background-color: #fcbf5c; /* code-orange */
  border-radius: 10px;
  border: 3px solid #232323; /* Matches the track background */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #79ff5b; /* code-green on hover */
}

/* For Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #fcbf5c #232323; /* code-orange for thumb and background-dark for track */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
